var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-manage" }, [
      _c("div", { attrs: { id: "blog-topImg" } }),
      _c("div", { staticClass: "blog-title-text mt-5 text-center" }, [
        _vm._v("フクベ(瓢) ＆ 笠置山")
      ]),
      _c("div", { staticClass: "container pb-5" }, [
        _c("div", { staticClass: "text-center blog-content-text" }, [
          _c("div", { staticClass: "mt-3" }, [_vm._v("2020/11/20")]),
          _c("div", { staticClass: "mt-3 text-left" }, [
            _c("p"),
            _c("p"),
            _c("p", [_vm._v("こんにちは。ヤマラボ管理人です。")]),
            _c("p"),
            _c("p", [_vm._v("先日、岐阜県にあるボルダリングエリア")]),
            _c("p"),
            _c("p", [
              _c("strong", [_vm._v("フクベ(瓢)")]),
              _vm._v("＆"),
              _c("strong", [_vm._v("笠置山")]),
              _vm._v("へ現地調査に行ってきました！")
            ]),
            _c("p"),
            _c("p", [
              _vm._v("初めての岐阜県の岩場。とても楽しみにしておりました。")
            ]),
            _c("p"),
            _c("p"),
            _c("p", [_vm._v("調査初日はフクベへ。")]),
            _c("p"),
            _c("p", [_vm._v("フクベは山に入る前に入山料が必要です。")]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "blogImg",
                attrs: {
                  src: "/img/blog/20201120/IMG_2304-300x225.jpg",
                  alt: ""
                }
              })
            ]),
            _c("p"),
            _c("p", [_vm._v("入山帳に氏名等を記入し、1人300円入金します。")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "朝8時頃に到着しましたが、既にボルダーの方何人かいらっしゃいました。"
              )
            ]),
            _c("p"),
            _c("p", [
              _vm._v("平日でしたが、フクベはとても人気エリアなんですね。")
            ]),
            _c("p"),
            _c("p", [
              _vm._v("今日から調査がんばるぞ！と張り切っていましたが・・・。")
            ]),
            _c("p"),
            _c("p", [_vm._v("なかなか目的の岩にたどり着けず調査は大難航。")]),
            _c("p"),
            _c("p", [_vm._v("去年の台風の影響か、林道が所々荒れていました。")]),
            _c("p"),
            _c("p", [
              _vm._v("中流エリアへのアプローチが分からず、調査を一度中断。")
            ]),
            _c("p"),
            _c("p", [
              _vm._v(
                "数々の岩場を調査しこなしてきたので、今回は悔しかったです。"
              )
            ]),
            _c("p"),
            _c("p", [
              _vm._v("もし、中流エリアへのアプローチ等に詳しい方いましたら")
            ]),
            _c("p"),
            _c("p", [
              _vm._v("ぜひ、"),
              _c("span", { staticStyle: { color: "#0000ff" } }, [
                _c(
                  "a",
                  {
                    staticClass: "blog-text-color",
                    staticStyle: { color: "#0000ff" },
                    attrs: {
                      href: "https://www.yamalab.jp/feed_back/input",
                      target: "_blank",
                      rel: "noopener noreferrer"
                    }
                  },
                  [_vm._v("こちら")]
                )
              ]),
              _vm._v("へお問い合わせください。")
            ]),
            _c("p"),
            _c("p", [_vm._v("みなさまのお問い合わせお待ちしております！")]),
            _c("p"),
            _c("p", [_vm._v("とはいえ")]),
            _c("p"),
            _c("p", [_vm._v("上流・山エリアはとても賑わっておりました。")]),
            _c("p"),
            _c("p", [_vm._v("小山田大さんが開拓されたG4の四段")]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "size-medium wp-image-510 aligncenter",
                attrs: {
                  loading: "lazy",
                  src: "/img/blog/20201120/2_5_6_7_8_9_11-300x225.jpg",
                  alt: "",
                  width: "300",
                  height: "225"
                }
              })
            ]),
            _c("p"),
            _c("p", [_vm._v("ものすごい迫力でした。")]),
            _c("p"),
            _c("p", [_vm._v("これを登れる小山田大さんも、ものすごい。")]),
            _c("p"),
            _c("p", [_vm._v("また、川エリアの方では美しい滝がちらほら。")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "ここで登るボルダリングは気持ちいいだろうなー！とちょっと休憩。"
              )
            ]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "size-medium wp-image-510 aligncenter",
                attrs: {
                  loading: "lazy",
                  src: "/img/blog/20201120/IMG_2436-300x225.jpg",
                  alt: "",
                  width: "300",
                  height: "225"
                }
              })
            ]),
            _c("p"),
            _c("p", [_vm._v("夕方4時頃になると日が落ちてくるので")]),
            _c("p"),
            _c("p", [_vm._v("このへんで、その日の調査は終了。")]),
            _c("p"),
            _c("p", [_vm._v("２日間にわたりフクベの調査を行いました。")]),
            _c("p"),
            _c("p", [_vm._v("撮れた岩のトポがヤマラボに追加できましたら")]),
            _c("p"),
            _c("p", [_vm._v("またこちらでお知らせします！")]),
            _c("p"),
            _c("p", [_vm._v("取れなかった岩はまたリベンジします！")]),
            _c("p"),
            _c("p", [_vm._v("次こそは見つけるぞ！！")]),
            _c("p"),
            _c("p"),
            _c("p", [_vm._v("調査3日目からは笠置山へ。")]),
            _c("p"),
            _c("p", [_vm._v("近年、注目を集めている笠置山。")]),
            _c("p"),
            _c("p", [_vm._v("すぐそばにキャンプサイトや、簡易トイレなど")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "看板も多くあり、エリアの周辺はとても整備されておりました。"
              )
            ]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "size-medium wp-image-510 aligncenter",
                attrs: {
                  loading: "lazy",
                  src: "/img/blog/20201120/IMG_2490-300x225.jpg",
                  alt: "",
                  width: "300",
                  height: "225"
                }
              })
            ]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "size-medium wp-image-510 aligncenter",
                attrs: {
                  loading: "lazy",
                  src: "/img/blog/20201120/IMG_2491-300x225.jpg",
                  alt: "",
                  width: "300",
                  height: "225"
                }
              })
            ]),
            _c("p"),
            _c("p", [_vm._v("笠置山もフクベ同様朝8時頃到着。")]),
            _c("p"),
            _c("p", [
              _vm._v("ここも朝早くからボルダーの方、何人かとすれ違いました。")
            ]),
            _c("p"),
            _c("p", [_vm._v("岐阜のボルダリングエリアは熱いですね…！")]),
            _c("p"),
            _c("p", [_vm._v("笠置山は1つ1つの岩がとても大きい印象です。")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "初めて笠置山に行かれる方でも、すぐ岩は見つけられそうです。"
              )
            ]),
            _c("p"),
            _c("p", [_vm._v("また、ボルダリングエリアのみならず")]),
            _c("p"),
            _c("p", [_vm._v("ルートクライミングエリアもあるので")]),
            _c("p"),
            _c("p", [
              _vm._v("トップロープ登られる方でもいい練習になりそうです。")
            ]),
            _c("p"),
            _c("p", [_vm._v("今回は")]),
            _c("p"),
            _c("p", [
              _vm._v("大岩コケエリア・電波塔エリア・テンプテーションエリア")
            ]),
            _c("p"),
            _c("p", [_vm._v("の岩を撮影。")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "この3エリアに留まらず、笠置山はさらに多くのエリアが存在します。"
              )
            ]),
            _c("p"),
            _c("p", [
              _vm._v("まだ開拓も進んでおり、通い続けても飽きない岩場ですね。")
            ]),
            _c("p"),
            _c("p", [
              _vm._v("調査が終わった後に見晴らし台岩からみる景色は最高でした。")
            ]),
            _c("p"),
            _c("p", [
              _c("img", {
                staticClass: "size-medium wp-image-510 aligncenter",
                attrs: {
                  loading: "lazy",
                  src: "/img/blog/20201120/IMG_2669-300x225.jpg",
                  alt: "",
                  width: "300",
                  height: "225"
                }
              })
            ]),
            _c("p"),
            _c("p", [_vm._v("移動も合わせて5泊6日の調査でしたが")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "6日間とも天候に恵まれ、最高のコンディションで調査することができました。"
              )
            ]),
            _c("p"),
            _c("p"),
            _c("p", [_vm._v("次回の現地調査の予定はまだ決まっていないですが")]),
            _c("p"),
            _c("p", [_vm._v("近日公開予定の岩場は")]),
            _c("p"),
            _c("p", [_vm._v("石川県にある、とあるボルダー。")]),
            _c("p"),
            _c("p", [_vm._v("お楽しみに！")]),
            _c("p")
          ]),
          _c("hr", {
            staticClass: "mt-5",
            attrs: { color: "#CCCCCC", width: "100%", size: "3" }
          }),
          _c("a", { staticClass: "d-inline-block", attrs: { href: "/blog" } }, [
            _c("div", { staticClass: "mt-5 blog-button px-4 py-1" }, [
              _vm._v("ブログ一覧")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }